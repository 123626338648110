import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import ChangePassword from "./pages/auth/ChangePassword";
import Privacy from "./pages/Privacy";
import TestS3 from "./pages/TestS3";

const App = () => {
   return (
      <Routes>
         <Route path="/changePassword" element={<ChangePassword/>}/>
         <Route path="/privacy" element={<Privacy/>}/>
         <Route path="/test-s3" element={<TestS3/>}/>
         <Route path="*" element={<Home/>}/>
      </Routes>
   );
}

export default App;
