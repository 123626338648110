import {createApi} from "@reduxjs/toolkit/query/react";
import {buildBaseQuery} from "../../baseQuery";

export const authApi = createApi({
   reducerPath: "authApi",
   baseQuery: buildBaseQuery("auth"),
   endpoints: (builder) => ({
      changePassword: builder.mutation<any,
         {
            password: string;
            passwordConfirmation: string;
            token: string;
         }>({
         query: (data) => ({
            url: "changePassword",
            method: "POST",
            body: data,
         }),
      }),
   }),
});

export const { useChangePasswordMutation } = authApi;
