import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useSelector} from 'react-redux';
import {authApi} from "./modules/auth/authApi";

const store = configureStore({
   reducer: {
      [authApi.reducerPath]: authApi.reducer,
   },
   middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat([
         authApi.middleware,
      ]),
});

export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
