import React from 'react';
import {useSearchParams} from "react-router-dom";
import {Formik} from 'formik';
import * as yup from 'yup';
import GuestLayout from "../../layouts/GuestLayout";
import {useChangePasswordMutation} from "../../store/modules/auth/authApi";

const ChangePassword = () => {

   const [searchParams] = useSearchParams();

   const [changePassword, {isError, isSuccess, isLoading}] = useChangePasswordMutation()

   const handleSubmit = (data: any) => {
      changePassword({
         ...data,
         token: searchParams.get("token") as string
      })
   }

   let validationSchema = yup.object({
      password: yup.string().required('Այս դաշտը պարտադիր է').min(6, "Պետք է լինի ամենաքիչը 6 սիմվոլ"),
      passwordConfirmation: yup.string()
         .required('Այս դաշտը պարտադիր է')
         .oneOf([yup.ref('password'), null], 'Գաղտնաբառերը պետք է համընկնեն')
   });

   return (
      <GuestLayout>
         <h1 className="text-center mb-5">Մուտքագրեք նոր գաղտնաբառը</h1>

         <Formik
            initialValues={{
               password: '',
               passwordConfirmation: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
         >
            {({
                 values,
                 errors,
                 handleChange,
                 handleBlur,
                 handleSubmit,
              }) => (
               <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                     <label>Գաղտնաբառ</label>
                     <input type="text"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            className="form-control"
                            placeholder="Գաղտնաբառ"
                            required/>
                     {errors.password && <p className="text-danger">{errors.password}</p>}
                  </div>

                  <label>Գաղտնաբառի կրկնություն</label>
                  <input type="text"
                         name="passwordConfirmation"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.passwordConfirmation}
                         className="form-control"
                         placeholder="Գաղտնաբառի կրկնություն"
                         required/>
                  {errors.passwordConfirmation && <p className="text-danger mb-3">{errors.passwordConfirmation}</p>}

                  {isError &&
                      <p className="text-danger text-center my-3">Գաղտնաբառի վերականգնման հղման ժամկետը լրացել է</p>}
                  {isSuccess && <p className="text-success text-center my-3">Գաղտնաբառն հաջողությամբ փոփոխվել է</p>}

                  <div className="d-flex justify-content-center pt-3">
                     <button type="submit" className="btn btn-primary" disabled={isLoading}>
                        {isLoading &&
                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"/>}
                        <span className="sr-only">Հաստատել</span>
                     </button>
                  </div>

               </form>
            )}
         </Formik>
      </GuestLayout>
   );
};

export default ChangePassword;
