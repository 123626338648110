import React from 'react';

const Privacy = () => {
   return <div className="container pt-5">
      <h1>Սովորել Անգլերեն 1.0.0 - Лицензионное соглашение</h1>
      <h2>Пользовательское Соглашение</h2>

      <p>Положения настоящих условий («Условий пользования») регулируют отношения между вами и ООО «ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ»
         (далее –
         («Սովորել Անգլերեն» или «мы» в любых падежах) в связи с использованием вами приложения (Сервиса) Սովորել
         Անգլերեն.
         Использование Сервиса также регулируется Политикой конфиденциальности Սովորել Անգլերեն и другими
         соответствующими
         правилами, которые являются частью настоящих условий и включены в них посредством отсылки.
         До получения доступа или начала пользования Сервисом, Սովորել Անգլերեն или доступ к приложению, вы должны
         принять
         настоящие Условия пользования и Политику конфиденциальности. От вас также может потребоваться
         зарегистрировать учетную
         запись для использования Сервиса («Учетная запись»). Регистрируя Учетную запись или используя Сервис иным
         образом, вы
         подтверждаете, что вам исполнилось 13 лет или более. Если вам от 13 до 17 лет, вы подтверждаете, что ваш
         законный
         представитель ознакомился и согласился с настоящими Условиями пользования.
         УСТАНАВЛИВАЯ, ПОЛЬЗУЯСЬ ИЛИ ИНЫМ ОБРАЗОМ ОСУЩЕСТВЛЯЯ ДОСТУП К СЕРВИСУ, ВЫ СОГЛАШАЕТЕСЬ С ПОЛОЖЕНИЯМИ
         НАСТОЯЩИХ УСЛОВИЙ
         ПОЛЬЗОВАНИЯ. ЕСЛИ ВЫ НЕ СОГЛАСНЫ С ПОЛОЖЕНИЯМИ НАСТОЯЩИХ УСЛОВИЙ ПОЛЬЗОВАНИЯ, НЕ УСТАНАВЛИВАЙТЕ, НЕ
         ИСПОЛЬЗУЙТЕ И ИНЫМ
         ОБРАЗОМ НЕ ОСУЩЕСТВЛЯЙТЕ ДОСТУП К СЕРВИСУ. ИСПОЛЬЗОВАНИЕ ДАННОГО СЕРВИСА ЯВЛЯЕТСЯ НЕДЕЙСТВИТЕЛЬНЫМ ТАМ, ГДЕ
         ПРОТИВОРЕЧИТ ЗАКОНУ.
         Սովորել Անգլերեն сохраняет за собой право на свое усмотрение изменять, модифицировать, дополнять или
         исключать части
         настоящих Условий пользования, Политики конфиденциальности и других соответствующих правил в любое время
         посредством
         размещения измененных условий в Сервисе Սովորել Անգլերեն Սովորել Անգլերեն. Вы будете считаться принявшим
         такие
         изменения в случае, если будете продолжать пользоваться Сервисом. Если в какой-либо момент вы не согласитесь
         с любой
         частью действительной на такой момент редакции Условий пользования, Политики конфиденциальности Սովորել
         Անգլերեն или
         иной политики, правил или кодексов поведения Սովորել Անգլերեն, относящихся к использованию вами Сервиса,
         ваша лицензия
         на использование Сервиса немедленно прекратится, и вы должны незамедлительно прекратить использование
         Сервиса.
      </p>

      <h3>1. Лицензия</h3>
      <h4>1.1. Предоставление ограниченной лицензии на использование Сервиса</h4>
      <p>
         В случае вашего согласия и непрерывного соблюдения настоящих Условий пользования и соответствующих политик
         Սովորել
         Անգլերեն, предоставляет вам неисключительную, не подлежащую передаче, в том числе в порядке
         сублицензирования,
         отзывную и ограниченную лицензию на доступ к Сервису и его использование для ваших личных некоммерческих
         целей. Вы
         соглашаетесь не использовать Сервис ни для какой другой цели.
         К использованию Сервиса применяются следующие ограничения:
         • Вы обязуетесь не создавать Учетную запись и не осуществлять доступ к Сервису, если вам менее 13 лет; вы
         предпримете
         действия по ограничению использования Сервиса несовершеннолетними и недопущению использования Сервиса детьми
         до 13
         лет. Вы принимаете на себя полную ответственность за любое использование вашей кредитной карты или иного
         платежного
         инструмента (например, PayPal) малолетними.
         • Вы обязуетесь не покупать, не продавать, не сдавать в аренду и не отдавать вашу Учетную запись, не
         создавать Учетную
         запись под чужим именем или с использованием ложных данных либо от имени других лиц (и не делать попыток
         осуществить
         упомянутые выше действия). Вы обязуетесь не использовать Сервис, если ваша учетная запись была ранее удалена
         Սովորել
         Անգլերեն либо если ваш доступ был ранее заблокирован.
         Информация для входа в систему и ваша Учетная запись
         Вам, возможно, потребуется выбрать пароль для вашей Учетной записи. Вы также можете использовать другие
         учетные
         данные, чтобы получить доступ к Учетной записи («Информация для входа в систему»). Вы обязуетесь ни с кем не
         делиться
         Учетной записью или Информацией для входа в систему, а также не допускать, чтобы кто-либо кроме вас получал
         доступ к
         вашей Учетной записи либо осуществлял любые иные действия, которые могут поставить под угрозу вашу Учетную
         запись. В
         случае если вам стало известно или вы обоснованно подозреваете, что имело место какое-либо нарушение
         безопасности,
         включая без ограничения любые случаи утраты, кражи или несанкционированного разглашения Информации для входа
         в
         систему, вы обязуетесь незамедлительно уведомить Սովորել Անգլերեն и изменить вашу Информацию для входа в
         систему. Вы
         несете исключительную ответственность за все случаи использования Информации, включая покупки, независимо от
         того,
         осуществляются ли они с вашего разрешения. Вы несете ответственность за все действия, связанные с вашей
         Учетной
         записью.
         Սովորել Անգլերեն сохраняет за собой право удалять или изменять любые имена пользователей в любое время и по
         любой
         причине, включая требования третьих лиц относительно нарушения каким-либо именем пользователя прав такой
         третьей
         стороны.
         Лицензионные ограничения
         Любое использование Сервиса в нарушение настоящих Лицензионных ограничений строго запрещается, может
         привести к
         незамедлительному отзыву вашей ограниченной лицензии и к ответственности за нарушение закона.
         Вы соглашаетесь ни при каких условиях:
         • Не совершать никаких действий, которые Սովորել Անգլերեն считает противоречащими смыслу или сути Сервиса,
         или
         приводящих к ненадлежащему использованию служб поддержки Սովորել Անգլերեն.
         • Не использовать обман (читы) и не принимать участие (прямо или косвенно) в их использовании, не
         эксплуатировать
         уязвимость системы, не использовать автоматизирующее программное обеспечение, ботов, программное обеспечение
         для
         взлома, модификации и любое иное несанкционированное программное обеспечение третьих лиц, предназначенное
         для
         вмешательства в Сервис.
         • Не изменять и не способствовать изменениям каких-либо файлов, которые являются частью Сервиса
         • Не нарушать, не мешать и иным образом отрицательно не влиять на нормальное функционирование Сервиса, и не
         действовать иным образом так, чтобы это могло негативно повлиять на опыт других пользователей при
         использовании
         Сервиса.
         • Не прерывать, перегружать и не содействовать или помогать прерыванию либо перегрузке любого компьютера или
         сервера
         («Сервер»), используемого для предложения или поддержки Сервиса.
         • Не создавать, не оказывать помощь и не участвовать иным образом в любом типе компьютерной атаки, в том
         числе в
         распространении вируса, направленной против Сервиса атаке типа «отказ в обслуживании», и не предпринимать
         любые иные
         попытки прервать работу Сервиса или использование Сервиса любым иным лицом.
         • Не пытаться получить несанкционированный доступ к Сервису, к зарегистрированным Учетным записям других
         пользователей
         или к компьютерам, Серверам или сетям, подсоединенным к Сервису любым способом за исключением
         пользовательского
         интерфейса, предоставляемого Սովորել Անգլերեն, включая обход или модификацию, попытку обхода или модификации
         либо
         подстрекательство какого-либо лица на обход или модификацию какого-либо средства защиты, технологии,
         устройства или
         программного обеспечения, являющегося частью Сервиса, либо содействие в этом.
         • Не предоставлять через Сервис доступ к какому-либо материалу или информации, которая нарушает любое
         авторское право,
         право на товарные знаки, патенты, промышленную тайну, право на неприкосновенность частной жизни, право на
         публичное
         использование или иное право любого лица или компании либо выдавать себя за любое другое лицо, в том числе
         работников
         Սովորել Անգլերեն.
         • Не осуществлять обратный инжиниринг, декомпиляцию, дизассемблирование, расшифровку или иным образом не
         пытаться
         извлечь исходный код любого программного обеспечения или иной интеллектуальной собственности, используемой
         для
         предоставления Сервиса Սովորել Անգլերեն, и не получать какой-либо информации из Сервиса Սովորել Անգլերեն,
         используя
         любой метод, кроме прямо разрешенных Սովորել Անգլերեն.
         • Не склонять к распространению и не пытаться склонять к распространению информации для входа в Учетную
         запись или
         других учетных данных либо личной информации из других источников Сервиса Սովորել Անգլերեն.
         .
         Սովորել Անգլերեն сохраняет за собой право определять, не нарушает ли поведение правила Условий пользования и
         не
         находится ли оно иным образом вне намерений или смысла настоящих Условий пользования или Сервиса как
         такового. Սովորել
         Անգլերեն сохраняет за собой право предпринимать действия по результатам такой оценки, которые могут привести
         к
         закрытию Учетной записи и запрету на использование вами Сервиса полностью или частично.
      </p>


      <h4>1.2. Приостановление действия и прекращение Учетной записи и Сервиса</h4>
      <p>
         БЕЗ ОГРАНИЧЕНИЯ ЛЮБЫХ ИНЫХ СРЕДСТВ ПРАВОВОЙ ЗАЩИТЫ, ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ МОЖЕТ ОГРАНИЧИТЬ, ПРИОСТАНОВИТЬ,
         ПРЕКРАТИТЬ,
         МОДИФИЦИРОВАТЬ ИЛИ УДАЛИТЬ УЧЕТНЫЕ ЗАПИСИ ИЛИ ДОСТУП К СЕРВИСУ И ЕГО ОТДЕЛЬНЫМ ЧАСТЯМ, ЕСЛИ ВЫ (ИЛИ ЕСЛИ
         ՍՈՎՈՐԵԼ
         ԱՆԳԼԵՐԵՆ ПОДОЗРЕВАЕТ, ЧТО ВЫ) НЕ СОБЛЮДАЕТЕ ЛЮБЫЕ ПОЛОЖЕНИЯ НАСТОЯЩИХ УСЛОВИЙ ПОЛЬЗОВАНИЯ В СВЯЗИ С ЛЮБЫМ
         ДЕЙСТВИТЕЛЬНЫМ ИЛИ ПОДОЗРЕВАЕМЫМ НЕЗАКОННЫМ, ИЛИ НЕСООТВЕТСТВУЮЩИМ ИСПОЛЬЗОВАНИЕМ СЕРВИСА С ПРЕДВАРИТЕЛЬНЫМ
         УВЕДОМЛЕНИЕМ ИЛИ БЕЗ ТАКОВОГО. ВЫ МОЖЕТЕ ЛИШИТЬСЯ СВОЕГО ПРОФИЛЯ В СЕРВИСЕ В РЕЗУЛЬТАТЕ ПРЕКРАЩЕНИЯ ИЛИ
         ОГРАНИЧЕНИЯ
         УЧЕТНОЙ ЗАПИСИ, А РАВНО ВСЕХ ВЫГОД И ПОКУПОК, СВЯЗАННЫХ С ИСПОЛЬЗОВАНИЕМ ВАМИ СЕРВИСА, ПРИ ЭТОМ ՍՈՎՈՐԵԼ
         ԱՆԳԼԵՐԵՆ НЕ
         НЕСЕТ НИКАКИХ ОБЯЗАННОСТЕЙ ПО КОМПЕНСАЦИИ ВАМ ЛЮБЫХ РАСХОДОВ ИЛИ ПОСЛЕДСТВИЙ.
         БЕЗ ОГРАНИЧЕНИЯ ЛЮБЫХ ИНЫХ СРЕДСТВ ЗАЩИТЫ, КОТОРЫМИ МЫ МОГЛИ БЫ ВОСПОЛЬЗОВАТЬСЯ, МЫ МОЖЕМ ОГРАНИЧИТЬ,
         ПРИОСТАНОВИТЬ
         ИЛИ ПРЕКРАТИТЬ СЕРВИС И ПОЛЬЗОВАТЕЛЬСКИЕ УЧЕТНЫЕ ЗАПИСИ ИЛИ ИХ ЧАСТИ, ЗАПРЕТИТЬ ДОСТУП К НАШИМ СЕРВИСАМ, И
         ИХ
         КОНТЕНТУ, ИНСТРУМЕНТАМ, ПРИНЯТЬ ТЕХНИЧЕСКИЕ И ЮРИДИЧЕСКИЕ МЕРЫ ДЛЯ ПРЕДОТВРАЩЕНИЯ ДОСТУПА ПОЛЬЗОВАТЕЛЕЙ К
         СЕРВИСУ В
         ТОМ СЛУЧАЕ, ЕСЛИ МЫ УБЕЖДЕНЫ В ТОМ, ЧТО ОНИ СОЗДАЮТ РИСК ИЛИ ВОЗМОЖНОСТЬ ЮРИДИЧЕСКОЙ ОТВЕТСТВЕННОСТИ,
         НАРУШАЮТ
         ИНТЕЛЛЕКТУАЛЬНЫЕ ПРАВА ТРЕТЬИХ ЛИЦ ИЛИ ДЕЙСТВУЮТ ВНЕ СООТВЕТСТВИЯ С БУКВОЙ ИЛИ ДУХОМ НАШИХ УСЛОВИЙ ИЛИ
         ПОЛИТИК. КРОМЕ
         ТОГО, В СООТВЕТСТВУЮЩИХ ОБСТОЯТЕЛЬСТВАХ И НА НАШЕ ЕДИНОЛИЧНОЕ УСМОТРЕНИЕ МЫ МОЖЕМ ПРИОСТАНОВИТЬ ИЛИ
         ПРЕКРАТИТЬ
         ДЕЙСТВИЕ УЧЕТНЫХ ЗАПИСЕЙ ПОЛЬЗОВАТЕЛЕЙ, КОТОРЫЕ МОГУТ ПОВТОРНО НАРУШАТЬ ИНТЕЛЛЕКТУАЛЬНЫЕ ПРАВА ТРЕТЬИХ ЛИЦ.
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ ОСТАВЛЯЕТ ЗА СОБОЙ ПРАВО ПРЕКРАТИТЬ ДЕЙСТВИЕ ЛЮБОЙ УЧЕТНОЙ ЗАПИСИ, КОТОРАЯ БЫЛА НЕАКТИВНА В
         ТЕЧЕНИЕ
         180 ДНЕЙ.
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ оставляет за собой право прекратить предложение и/или поддержку Сервиса либо части Сервиса
         в любое
         время, при этом в момент такой приостановки автоматически прекращается предоставленная вам лицензия на
         использование
         Сервиса или его части. В таком случае ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ не будет нести обязанность по предоставлению
         возмещения,
         преимуществ или иной компенсации пользователям в связи с прекращением доступа к Сервису. Прекращение
         действия вашей
         Учетной записи может включать прекращение вашего доступа к Сервису или любой его части, включая любой
         контент,
         предоставленный вам или другими пользователям.
         Вы можете прекратить действие своей Учетной записи в любое время и на любом основании посредством письма на
         электронную почту englishgalaxycorp@gmail.com проинформировав ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ о том, что вы желаете
         прекратить
         действие Учетной записи.

      </p>
      <h3>2. Права</h3>
      <h4>
         2.1. Сервис
      </h4>
      <p>
         Все права на Сервис (названия, компьютерные коды, темы, объекты, персонажей, имена персонажей, сценарии,
         диалоги,
         крылатые фразы, концепции, художественное оформление, анимацию, звуки, музыкальные композиции,
         аудиовизуальные
         эффекты,
         методы работы, личные неимущественные права, документацию, информацию о профиле, программное обеспечение
         принадлежат
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ. ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ сохраняет за собой все права, включая интеллектуальные права или иные
         имущественные
         права, возникающие в связи со своим приложением Сервисом.
      </p>
      <h4>2.2. Учетные записи</h4>
      <p>
         НЕСМОТРЯ НА ЛЮБЫЕ ПОЛОЖЕНИЯ НАСТОЯЩИХ УСЛОВИЙ, ПРЕДУСМАТРИВАЮЩИЕ ОБРАТНОЕ, ВЫ ПРИЗНАЕТЕ И СОГЛАШАЕТЕСЬ, ЧТО
         НЕ
         ОБЛАДАЕТЕ
         НИКАКИМИ ПРАВАМИ, В ТОМ ЧИСЛЕ ИМУЩЕСТВЕННЫМИ, НА УЧЕТНУЮ ЗАПИСЬ, А ТАКЖЕ ПРИЗНАЕТЕ И СОГЛАШАЕТЕСЬ, ЧТО ВСЕ
         ПРАВА В
         ОТНОШЕНИИ УЧЕТНОЙ ЗАПИСИ ПРИНАДЛЕЖАТ И БУДУТ ПРИНАДЛЕЖАТЬ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ И ДЕЙСТВОВАТЬ В ИНТЕРЕСАХ
         КОМПАНИИ.
      </p>
      <h4>2.3. Удаление аккаунта</h4>
      <p>
         Вы можете удалить свою учетную запись, перейдя на страницу <a target="_blank" href="https://delete-account.angleren.online">удаления учетной записи.</a>.
      </p>
      <h3>3. Оплата и условия покупки</h3>
      <h4>3.1. Покупки</h4>
      <p>
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ предлагает платные продукты через google play, App store и другие платежные платформы. Что
         бы
         совершить
         покупку в ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ Вам потребуется подтвердить оплату у соответствующего оператора и выбрать способ
         оплаты
         (банковская карта или другие платежные сервисы) этот платежный способ будет использован для списания
         стоимости
         выбранной
         в приложении услуги, включая все необходимые налоги и комиссии. Подтверждая оплату, Вы даете согласие на
         списание
         соответствующей суммы. Если с помощью приложения Вы приобретаете подписку с автоматическим продлением, плата
         за
         подписку
         будет периодически списываться с помощью вашего способа оплаты до тех пор, пока Вы ее не отмените. По
         истечении срока
         первоначальной подписки и последующих периодов она будет автоматически продлена на соответствующий срок по
         цене,
         принятой вами при оформлении подписки.
      </p>
      <h4>3.2. Автоматическая оплата картой / автопродление</h4>
      <p>
         Подписка будет автоматически продлеваться до тех пор, пока не будет отменена или приостановлена. После
         приобретения
         подписки с Вас ежемесячно будет взиматься сумма платежа, соответствующая сумме, на которую Вы дали согласие
         в момент
         подписки; списание происходит в течение 24 часов до даты первоначальной оплаты; оплата производится
         выбранным при
         подписке способом. Платежные реквизиты банковской карты сохраняются и в дальнейшем используются для
         автоматической
         оплаты картой согласно условиям Соглашения стороннего платежного сервиса.
         Если Вы не согласны с уже списанным платежом, направьте свои возражения в службу поддержки клиентов,
         соответствующему
         стороннему платежному сервису. Вы также можете опротестовать платеж, обратившись в банк или к провайдеру
         платежных
         услуг, которые проинформируют вас о Ваших правах и установленных сроках возврата.
         Вы можете в любой момент в одностороннем порядке отменить свое согласие на автоматическую оплату картой в
         настройках
         соответствующего стороннего платежного сервиса, однако Вы обязаны оплатить все причитающиеся на тот момент
         суммы.
         Чтобы изменить подписку или отменить ее, войдите в аккаунт стороннего платежного провайдера и следуйте
         инструкциям по
         отмене подписки (даже если Вы удалили свой аккаунт или приложение ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ со своего устройства).
         При удалении
         аккаунта ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ или приложения ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ с устройства подписка не отменяется и ее действие
         не
         прекращается; ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ продолжит списывать средства, используя выбранный способ оплаты, пока
         подписка не будет
         отменена в аккаунте платежного провайдера. После отмены подписки Вы можете продолжать пользоваться ей до
         окончания уже
         оплаченного периода, по истечении этого периода подписка не продлевается.
      </p>
      <h3>4.1. Осуществление платежей</h3>
      <p>
         Вы соглашаетесь оплатить любые сборы и соответствующие налоги, необходимость оплаты которых возникла у вас
         или любого
         лица, использующего зарегистрированную на вас Учетную запись. ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ может пересмотреть стоимость
         товаров и
         услуг, предлагаемых посредством Сервиса в любое время. ВЫ ПРИЗНАЕТЕ, ЧТО ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ НЕ ОБЯЗАНА
         ПРЕДОСТАВЛЯТЬ
         ВОЗМЕЩЕНИЕ НИ ПО КАКОМУ ОСНОВАНИЮ И ЧТО ВЫ НЕ МОЖЕТЕ ПОЛУЧАТЬ ДЕНЬГИ ИЛИ ИНУЮ КОМПЕНСАЦИЮ, КОГДА ВАША
         УЧЕТНАЯ ЗАПИСЬ
         ЗАКРЫВАЕТСЯ, НЕЗАВИСИМО ОТ ТОГО, БЫЛО ЛИ ТАКОЕ ЗАКРЫТИЕ ДОБРОВОЛЬНЫМ ИЛИ НЕТ.
      </p>
      <h3>5. Обновление Сервиса</h3>
      <p>
         Вы понимаете, что Сервис развивается. ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ может потребовать от вас, чтобы вы приняли обновления
         Сервиса
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, которые вы установили на своем устройстве или компьютере. Вы признаете и соглашаетесь, что
         ՍՈՎՈՐԵԼ
         ԱՆԳԼԵՐԵՆ может обновлять Сервис ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, уведомляя вас об этом или нет. Вам может потребоваться
         время от
         времени обновлять программное обеспечение третьих лиц для того, чтобы пользоваться Сервисом ՍՈՎՈՐԵԼ
         ԱՆԳԼԵՐԵՆ.
      </p>
      <h3>6. Отказ от гарантий</h3>
      <p>
         БЕЗ ОГРАНИЧЕНИЯ ОТВЕТСТВЕННОСТИ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, СЕРВИС ПРЕДОСТАВЛЯЕТСЯ ДЛЯ ИСПОЛЬЗОВАНИЯ ВАМИ «В
         СУЩЕСТВУЮЩЕМ
         СОСТОЯНИИ» И «ПО МЕРЕ ДОСТУПНОСТИ» БЕЗ КАКИХ-ЛИБО ПРЯМЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ ГАРАНТИЙ, В ТОМ ЧИСЛЕ ГАРАНТИЙ
         СООТВЕТСТВИЯ
         ОЖИДАНИЯМ ПОКУПАТЕЛЯ, СООТВЕТСТВИЯ ОПРЕДЕЛЕННОЙ ЦЕЛИ, ЧИСТОТЫ ПРАВА СОБСТВЕННОСТИ, НЕНАРУШЕНИЙ ПРАВ, А ТАКЖЕ
         ГАРАНТИЙ,
         ВОЗНИКАЮЩИХ В РЕЗУЛЬТАТЕ ОБЫЧНОЙ ПРАКТИКИ ДЕЛОВЫХ ОПЕРАЦИЙ ИЛИ ОБЫЧАЕВ ДЕЛОВОГО ОБОРОТА. ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ НЕ
         ГАРАНТИРУЕТ, ЧТО ВЫ БУДЕТЕ ИМЕТЬ ВОЗМОЖНОСТЬ ДОСТУПА К СЕРВИСУ ИЛИ ЕГО ИСПОЛЬЗОВАНИЯ В ЛЮБОЕ ВРЕМЯ ИЛИ В
         ЛЮБОМ МЕСТЕ
         НА
         ВАШЕ УСМОТРЕНИЕ, ЧТО СЕРВИС НЕ БУДЕТ ПРЕРЫВАТЬСЯ И НЕ БУДЕТ СОДЕРЖАТЬ ОШИБОК, ЧТО НЕДОСТАТКИ БУДУТ
         ИСПРАВЛЕНЫ, А ТАКЖЕ
         ЧТО СЕРВИС НЕ БУДУТ СОДЕРЖАТЬ ВИРУСОВ ИЛИ ИНЫХ ВРЕДОНОСНЫХ КОМПОНЕНТОВ.
         Некоторые юрисдикции не позволяют исключить определенные гарантии. Соответственно, некоторые из
         вышеперечисленных
         заявлений об отказе от ответственности могут не применяться к вам.
      </p>
      <h3>
         7. Ограничение ответственности; единственное и исключительное средство защиты; возмещение убытков
      </h3>
      <p>
         НАСКОЛЬКО ЭТО РАЗРЕШЕНО ЗАКОНОМ, ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ПЕРЕД ВАМИ ЗА ЛЮБЫЕ КОСВЕННЫЕ,
         СОПУТСТВУЮЩИЕ,
         ШТРАФНЫЕ ИЛИ ИНЫЕ ПОДОБНЫЕ УБЫТКИ, В ТОМ ЧИСЛЕ УПУЩЕННЫЙ ДОХОД, УПУЩЕННУЮ ПРИБЫЛЬ, ПОТЕРЮ ДАННЫХ ИЛИ
         ПРЕРЫВАНИЕ
         ХОЗЯЙСТВЕННОЙ ДЕЯТЕЛЬНОСТИ ЛИБО ИНЫЕ НЕМАТЕРИАЛЬНЫЕ ПОТЕРИ (НЕЗАВИСИМО ОТ ИХ КОЛИЧЕСТВЕННОГО ВЫРАЖЕНИЯ),
         ВОЗНИКАЮЩИЕ В
         СВЯЗИ С НАСТОЯЩИМИ УСЛОВИЯМИ ПОЛЬЗОВАНИЯ ИЛИ САМИМ СЕРВИСОМ, НЕЗАВИСИМО ОТ ТОГО, ВЫТЕКАЮТ ЛИ ОНИ ИЗ
         ДОГОВОРА, ДЕЛИКТА
         ИЛИ ЛЮБОЙ ДРУГОЙ ЮРИДИЧЕСКОЙ КОНЦЕПЦИИ, И НЕЗАВИСИМО ОТ ТОГО, СООБЩАЛОСЬ ЛИ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ О ВОЗМОЖНОСТИ
         ТАКИХ
         УБЫТКОВ. НАСКОЛЬКО ЭТО НЕ ЗАПРЕЩЕНО ЗАКОНОМ, ОТВЕТСТВЕННОСТЬ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ ПЕРЕД ВАМИ ОГРАНИЧЕНА
         РАЗМЕРОМ, НЕ
         ПРЕВЫШАЮЩИМ СУММЫ, УПЛАЧЕННОЙ ВАМИ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ НА ОСНОВАНИИ НАСТОЯЩИХ УСЛОВИЙ ПОЛЬЗОВАНИЯ ЗА ПЕРИОД, НЕ
         ПРЕВЫШАЮЩИЙ
         ШЕСТИ (6) МЕСЯЦЕВ, НЕПОСРЕДСТВЕННО ПРЕДШЕСТВУЮЩИХ ДАТЕ, НА КОТОРУЮ ВЫ ПЕРВЫЙ РАЗ ЗАЯВИЛИ О ТАКИХ УБЫТКАХ. ВЫ
         ПРИЗНАЕТЕ
         И
         СОГЛАШАЕТЕСЬ, ЧТО ЕСЛИ ВЫ НИЧЕГО НЕ ПЛАТИЛИ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ В ТЕЧЕНИЕ ТАКОГО ПЕРИОДА ВРЕМЕНИ, ВАШИМ
         ЕДИНСТВЕННЫМ
         СРЕДСТВОМ ЗАЩИТЫ (И ЕДИНСТВЕННОЙ ОТВЕТСТВЕННОСТЬЮ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ) В СВЯЗИ С ЛЮБЫМ СПОРОМ С ՍՈՎՈՐԵԼ
         ԱՆԳԼԵՐԵՆ ЯВЛЯЕТСЯ
         ПРЕКРАЩЕНИЕ ИСПОЛЬЗОВАНИЯ СЕРВИСА И АННУЛЯЦИЯ ВАШЕЙ УЧЕТНОЙ ЗАПИСИ.
         НИЧТО В НАСТОЯЩИХ УСЛОВИЯХ ПОЛЬЗОВАНИЯ НЕ ВЛИЯЕТ НА ЗАКОННЫЕ ПРАВА ЛЮБОГО ПОТРЕБИТЕЛЯ, НЕ ИСКЛЮЧАЕТ И НЕ
         ОГРАНИЧИВАЕТ
         КАКУЮ-ЛИБО ОТВЕТСТВЕННОСТЬ ЗА ГРУБУЮ НЕОСТОРОЖНОСТЬ ИЛИ УМЫШЛЕННЫЕ ВИНОВНЫЕ ДЕЙСТВИЯ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, А
         ТАКЖЕ ЗА
         СМЕРТЬ
         ИЛИ ПРИЧИНЕНИЕ ВРЕДА ЛИЧНОСТИ, ПРОИСТЕКАЮЩИЕ ИЗ ЛЮБОГО НЕОСТОРОЖНОГО ИЛИ МОШЕННИЧЕСКОГО ДЕЯНИЯ ՍՈՎՈՐԵԼ
         ԱՆԳԼԵՐԵՆ.
         Вы соглашаетесь защитить и оградить ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ (а также наших должностных лиц, директоров, агентов,
         дочерние
         компании, совместные предприятия и работников) от любого иска, требования, возмещения убытков или иного
         ущерба,
         включая
         обоснованные вознаграждения адвокатов, заявленных любым третьим лицом и проистекающих из Сервиса либо
         возникающих в
         связи с его использованием вами, либо от любого нарушения вами настоящих Условий пользования. Однако
         вышесказанное не
         применяется, если нарушение прав не является следствием вашего умышленного или неосторожного поведения.
      </p>
      <h3>8. Разрешение споров и применимое право</h3>
      <p>
         В случае, если между вами и ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ возникает спор, мы настоятельно рекомендуем вам первоначально
         связаться
         непосредственно с нами посредством обращения в нашу службу поддержки по электронному адресу
         englishgalaxycorp@gmail.com
         Если вы являетесь резидентом Российской федирации, то действие настоящих Условий пользования, а также любые
         споры,
         возникающие в связи или связанные с ними, регулируются во всех своих аспектах в соответствии с
         законодательством
         Российской федерации. Вы соглашаетесь с тем, что любое требование или спор, который вы можете инициировать
         против
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, подлежит исключительной юрисдикции суда, расположенного в г Москве Российской федерации.
         Если вы
         являетесь резидентом иного государства, вы соглашаетесь с тем, что любой спор между вами и ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ
         подлежит
         урегулированию в соответствии с законодательством Российской Федерации. Вы соглашаетесь с тем, что, любое
         требование
         или
         спор, который вы можете инициировать против ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, подлежит исключительной юрисдикции суда,
         расположенного
         в
         Москве.
      </p>
      <h3>9. Автономность положений</h3>
      <p>
         Вы и ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ соглашаетесь с тем, что если любая часть настоящих Условий пользования или Политики
         конфиденциальности ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ полностью или частично будет признана компетентным судом
         недействительной или не
         имеющей юридической силы, то только такая часть настоящих Условий пользования, признанная недействительной
         или не
         имеющей юридической силы, будет считаться недействительной в данной юрисдикции, и это не будет влиять на
         действительность или юридическую силу в любом другом порядке и любой юрисдикции остальных частей настоящих
         Условий
         пользования, которые сохраняют свою полную силу и действие.
      </p>
      <h3>10. Общие положения</h3>
      <h4>
         10.1. Уступка
      </h4>
      <p>
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ может уступить или передать исполнение настоящих Условий пользования и/или Политики
         конфиденциальности
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ полностью или частично любому физическому и юридическому лицу в любое время с вашего
         согласия или без
         него. Вы не можете уступать или передавать какие-либо права и обязанности, вытекающие из Условий пользования
         или
         Политики конфиденциальности, без предварительного письменного согласия ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ. Любая уступка или
         передача
         прав, совершенная вами без такого согласия, считается недействительной.
      </p>
      <h3>10.2. Дополнительные политики</h3>
      <p>
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ может публиковать дополнительные политики, относящиеся к определенным сервисам, таким как
         форумы,
         состязания или программы лояльности. Ваше право использовать такие сервисы регулируется такими политиками и
         настоящими
         Условиями пользования.
      </p>
      <h3>10.3. Полнота соглашения</h3>
      <p>
         Настоящие Условия пользования, любые дополнительные политики и иные документы, прямо включенные в них
         посредством
         отсылки (включая Политику конфиденциальности ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ), представляют собой полную договоренность
         между вами и
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ и отменяют все предшествующие договоренности сторон, относящиеся к предмету данных Условий
         пользования,
         независимо от того, были ли они оформлены в электронной, устной либо письменной форме или были ли они
         установлены
         обычаем, практикой, политикой или прецедентом.
      </p>
      <h3>10.4. Отсутствие отказа от права требовать исполнения условий
      </h3>
      <p>
         Невозможность со стороны ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ требовать от вас строгого исполнения любого положения настоящих
         Условий
         пользования или Политики конфиденциальности ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, понудить вас к этому либо невозможность
         реализовать
         любое
         установленное ими право не может быть истолкована как отказ или освобождение ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ от права
         утверждать о
         действительности такого положения или права либо возможности ссылаться на него в данном и в любом другом
         случае.
         Прямой отказ ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ от любого положения, условия или требования настоящих Условий пользования или
         Политики
         конфиденциальности O ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ не освобождает от необходимости соблюдать такое положение, условие или
         требование
         в будущем.
         Если иное прямо не указано в настоящих Условиях пользования, никакие заявления, согласия, отказы или иные
         действия и
         упущения ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ не имеют юридической силы и не могут быть истолкованы как изменение настоящих
         Условий
         пользования, за исключением случаев, когда они выражены в письменной форме на бумажном носителе с вашей
         подписью и
         подписью уполномоченного должностного лица ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ.
      </p>
      <h3>10.5. Уведомления</h3>
      <p>
         Мы можем отправлять вам уведомления посредством размещения информации посредством электронной почты или
         любого иного
         способа коммуникации в соответствии с предоставленной вами контактной информацией. Все уведомления,
         направляемые вами
         или требуемые от вас на основании настоящих․
      </p>
      <h3>10.6. Средства правовой защиты по праву справедливости</h3>
      <p>
         Вы признаете, что определенные настоящими Условиями пользования права и обязанности ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ
         уникальны и
         незаменимы по своей природе и их нарушение нанесет невосполнимый ущерб ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, который не может
         быть
         исключительно возмещен в материальной форме, вследствие чего ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ будет иметь право на
         обеспечительные
         меры
         или иные средства правовой защиты по праву справедливости (без обязанности внесения судебного залога,
         поручительства
         или
         подтверждения ущерба) в случае любого нарушения или отказа от исполнения с вашей стороны.
         Вы безоговорочно отказываетесь требовать применения обеспечительных мер или иных средств правовой защиты по
         праву
         справедливости, а также наложения запрета или ограничения на Сервис ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, использование любых
         рекламных
         или
         иных связанных с ними материалов, а также от использования Сервиса или любого контента или материала,
         который
         используется или отображается посредством Сервиса, и соглашаетесь ограничить ваши требования требованиями о
         возмещении
         денежного ущерба, ограниченными разделом 7 (в случае их наличия).
      </p>
      <h3>10.7. Форс-мажор</h3>
      <p>
         ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ не несет ответственности за любую задержку или невозможность исполнения обязательств,
         вызванную
         причинами, находящимися за пределами разумного контроля ՍՈՎՈՐԵԼ ԱՆԳԼԵՐԵՆ, включая помимо прочего любую
         невозможность
         исполнить обязательства в связи с непредвиденными обстоятельствами или причинами, не контролируемыми ՍՈՎՈՐԵԼ
         ԱՆԳԼԵՐԵՆ,
         такими как стихийные бедствия, войны, террористические акты, массовые беспорядки, эмбарго, акты гражданских
         или
         военных
         властей, пожары, наводнения, несчастные случаи, забастовки или перебои в работе транспортной инфраструктуры,
         недостаток
         топлива, энергии, трудовых ресурсов или материалов.
      </p>


   </div>
};

export default Privacy;
