import React, {FC} from 'react';

interface GuestLayoutProps {
   children?: React.ReactNode
}

const GuestLayout: FC<GuestLayoutProps> = ({children}) => {
   return (
      <div className="container pt-5">
         {children}
      </div>
   );
};

export default GuestLayout;
