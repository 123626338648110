import React from 'react';


const baseUrl = 'https://s3.timeweb.com/cx28083-files/images_by_name'

const TestS3 = () => {

   const images = [
      'can', 'we', 'it', 'help', 'me', 'they', 'learn', 'speak', 'city', 'online', 'Russia', 'in', 'read', 'school', 'song', 'buy', 'language', 'ticket'
   ]

   return (
      <div className="container my-5">
         <h1 className="text-center">Timeweb s3 loading test</h1>

         {
            images.map(image =>
               <img
                  style={{
                     width: 150,
                     height: 150,
                     margin: 30
                  }}
                  className="border border-dark p-2 rounded"
                  alt={image} key={image} src={`${baseUrl}/${image}.png`}/>
            )
         }
      </div>
   );
};

export default TestS3;
